import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

type DrawerProps = {
  children: JSX.Element[] | null;
  isOpen: boolean;
  closeMenu: () => void;
};

const DrawerMenu = ({ children, isOpen, closeMenu }: DrawerProps) => {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* 
    timeout is used to delay the CSS transitions until the next event cycle,
    giving React enough time properly render the menu before the transitions
  */
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isOpen) {
      timeout = setTimeout(() => closeMenu());
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fadeTransition = classNames(
    'duration-500',
    'transition-all',
    'ease-in-out',
    {
      'opacity-100': isOpen,
      'opacity-0 invisible': !isOpen,
    },
  );

  const closeWithKey = (event: { key: string }) => {
    if (event.key === 'Escape') {
      closeMenu();
    }
  };

  return (
    <React.Fragment>
      {/* Opaque Background */}
      <div
        className={`fixed overflow-hidden z-10 bg-white bg-opacity-25 inset-0 ${fadeTransition}`}
      >
        <div
          className="w-screen h-full cursor-pointer"
          onClick={closeMenu}
          onKeyDown={closeWithKey}
          role="menuitem"
          tabIndex={0}
          aria-label="Close Menu"
        />
      </div>
      {/* Drawer Menu */}
      <div
        className={`fixed w-60 max-w-lg left-0 z-50 bg-white h-full rounded-r-5xl ${fadeTransition}`}
      >
        {/* Menu Items */}
        <div className={`ml-6 mt-5 text-lg space-y-${isLandscape ? '2' : '4'}`}>
          {children?.map(child => (
            <div key={child.key}>{child}</div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DrawerMenu;
